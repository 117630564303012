import React from "react";
import { Layout } from "../../components/Layout";
import { Tile } from "../../components/Tile";
import { graphql } from "gatsby";
import SEO from "../../components/SEO";

const Gallery = ({ data }) => (
  <Layout>
    <SEO title="Gallery" />
    <div className="page-title">
      <span>Gallery</span>
    </div>
    <div className="page-content gallery">
      <Tile
        title="Urbanscape"
        description="Collection of photographs from my travels showing a mixture of the urban architecture and day-to-day adventures"
        target="/gallery/urbanscape"
        tags={["travels", "architecture"]}
        backgroundImageName={data.nycImageUrl.publicURL}
      />
    </div>
  </Layout>
);

export default Gallery;

export const query = graphql`
  query getGalleryImagePath {
    nycImageUrl: file(
      name: { eq: "urbanscape-crop" }
      sourceInstanceName: { eq: "images" }
    ) {
      name
      publicURL
    }
  }
`;
